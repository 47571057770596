import React from "react"
import { PageProps } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageTitle from "../../components/utils/PageTitle"
import "../../components/Contact/style.css"

const Contact: React.FC<PageProps> = ({ location }) => {
  const description = "dcapital 株式会社"
  return (
    <Layout location={location} selectPageName={"Contact"}>
      <SEO
        title="Contact"
        description={description}
        lang={"ja"}
        isNoRobots={true}
      />
      <PageTitle title="Contact" />
      <div className="thankyou-message-wrapper">
        <div className="thankyou-message-content-wrapper">
          <p className="thankyou-page-message-1">
            メッセージのご送信ありがとうございます。
          </p>
        </div>
        <div className="thankyou-message-content-wrapper">
          <p className="thankyou-page-message-2">
            担当者が確認の上、ご連絡差し上げます。今しばらくお待ち下さい。
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
